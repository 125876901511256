import React from "react";
import "./App.scss";
import Router from "./router/router";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useHref } from "react-router-dom";
import { AppFooter } from "./components/layout/Layout";
import GuestState from "./context/Guest/GuestState";

const App = () => {
  return (
    <div className="main">
      <GuestState>
        <div className="content">
          <Grid2 container>
            <Router />
          </Grid2>
        </div>
        <AppFooter />
      </GuestState>
    </div>
  );
};

export default App;
