import React, { useReducer } from "react";

import GuestReducer from "./GuestReducer";
import GuestContext from "./GuestContext";
import { SET_GUEST } from "./types";

const GuestState = (props) => {
  let initialState = {
    guests: [],
  };
  const [state, dispatch] = useReducer(GuestReducer, initialState);

  const setGuests = (guests) => {
    dispatch({ type: SET_GUEST, payload: { ...guests } });
  };

  return (
    <GuestContext.Provider
      value={{
        guests: state.guests,
        setGuests,
      }}
    >
      {props.children}
    </GuestContext.Provider>
  );
};

export default GuestState;
