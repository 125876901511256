import axios from "axios";
import { GUESTS, INVITATIONS } from "./endpoints";
import Airtable from "airtable";
var base = new Airtable({
  apiKey:
    "pat1DH9LkpkcEmNex.869cd0da2a5bccfca4f35babe32976d962707130022ffaf5a268bce5ed68fa60",
}).base("appnXS2OfZ7pV72w8");

const RequestService = {
  localStorage: {
    storeGuest: (guests) => {
      localStorage.setItem("guests", JSON.stringify(guests));
    },
    retrieveGuest: () => {
      const storedGuests = localStorage.getItem("guests");
      return storedGuests ? JSON.parse(storedGuests) : [];
    },
  },
  airtable: {
    guests: {
      updateGuest: async (guest, updatedGuest) => {
        return new Promise((resolve, reject) => {
          base("Guests").update(
            guest["guestId"],
            updatedGuest,
            function (err, record) {
              if (err) {
                console.error(err);
                reject(err);
                return;
              }
              resolve(record);
            }
          );
        });
      },
    },
    invitations: {
      findGuestInvitation: async (guestName) => {
        return new Promise((resolve, reject) => {
          base("Invitations")
            .select({
              filterByFormula: `FIND(LOWER("${guestName}"), LOWER({Name (from Invitees)}&""))`,
              maxRecords: 5,
            })
            .eachPage(
              function page(records, fetchNextPage) {
                resolve(records);
                fetchNextPage();
              },
              function done(err) {
                if (err) {
                  console.error(err);
                  return;
                }
              }
            );
        });
      },

      searchInvitation: async (invitationId) => {
        return new Promise((resolve, reject) => {
          base("Invitations").find(invitationId, function (err, record) {
            if (err) {
              console.error(err);
              reject(err);
              return;
            }
            resolve(
              record.fields.Invitees.map((id, index) => ({
                Name: record.fields["Name (from Invitees)"][index],
                attending: "",
                mealChoice: "",
                diet: "",
                advice: "",
                guestId: id,
                guestType:
                  record.fields["Type of Guest (from Invitees)"][index],
              }))
            );
          });
        });
      },
    },
  },
  guest: {
    getGuests: async (query) => {
      return await axios
        .get(GUESTS.getAll.path, {
          params: query,
        })
        .then((res) => {
          return res?.data?.guests;
        })
        .catch((error) => {
          return error?.response?.data;
        });
    },
  },
  invitation: {
    getInvitations: async (query) => {
      return await axios
        .get(INVITATIONS.getAll.path, {
          params: query,
        })
        .then((res) => {
          return res?.data?.invitations;
        })
        .catch((error) => {
          return error?.response?.data;
        });
    },
    getInvitation: async (invitationId) => {
      return await axios
        .get(INVITATIONS.getSingle.path(invitationId))
        .then((res) => {
          return res?.data?.invitations;
        })
        .catch((error) => {
          return error?.response?.data;
        });
    },
  },
};
export default RequestService;
