import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import RequestService from "../../services/Request.service";
import "./rsvp-form.scss";
import { Tag } from "@carbon/react";
import {
  bridalPaartyCalendar,
  calendar,
  rsvp,
} from "../../config/FormConstants";
import { church, nac, champagne, sheepFamily } from "../../config/images";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { ArrowLeft, ArrowRight, Reset, Save } from "@carbon/icons-react";

const Form = ({ guests = [], setGuests }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const isGuestBridalParty = (guests) => {
    return guests.some(
      (guest) =>
        guest?.guestType === "Parents" ||
        guest?.guestType === "Grandparents" ||
        guest?.guestType === "Bridesmaid" ||
        guest?.guestType === "Groomsmen" ||
        guest?.guestType === "Test"
    );
  };

  const handleNext = () => {
    if (activeStep === 1) {
      const allNotJoining = guests.every(
        (guest) => guest?.rsvpReception === rsvp.status.notAttending
      );
      if (allNotJoining) {
        setActiveStep(
          isGuestBridalParty(guests)
            ? bridalPartySteps.length - 1
            : steps.length - 1
        );
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (
      activeStep !== 0 &&
      guests.every((guest) => guest?.rsvpReception === rsvp.status.notAttending)
    ) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const getLabel = (selectedOption, data) => {
    const selected = data.find((option) => option.value === selectedOption);
    return selected ? selected.label : "Invalid option";
  };

  const handleReset = () => {
    setActiveStep(0);
    setIsConfirmed(false);
    setGuests((prevGuests) =>
      prevGuests.map((guest) => ({
        ...guest,
        mealChoice: "",
        diet: "",
        advice: "",
      }))
    );
  };

  const handleSubmit = () => {
    setIsConfirmed(true);
    guests.forEach(async (guest) => {
      const {
        Name,
        guestId,
        guestType,
        attending,
        rsvpRehearsal,
        ...updatedGuest
      } = guest;
      if (rsvpRehearsal === "" || rsvpRehearsal === rsvp.status.notAttending) {
        updatedGuest.rsvpRehearsal = rsvp.status.notAttending;
      } else if (rsvpRehearsal === rsvp.status.attending) {
        updatedGuest.rsvpRehearsal = rsvp.status.attending;
      }
      await RequestService.airtable.guests.updateGuest(guest, updatedGuest);
    });
  };

  const handleChange = (event, index, field) => {
    const { value } = event?.target;
    setGuests((prevGuests) =>
      prevGuests.map((guest, i) =>
        i === index ? { ...guest, [field]: value } : guest
      )
    );
  };

  const steps = [
    {
      label: "Attending Ceremony",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.rsvpCeremony.label}
          </FormLabel>
          <div className="view">
            <img src={church} alt="hello" className="ceremony-image" />
            <div className="details">
              <div className="title">Notre Dame Cathedral Basilica </div>
              <div className="address">
                56 Guigues Ave, Ottawa, Ontario K1N 5H5{" "}
              </div>
              <div className="time"> 11:00 AM ceremony starts</div>
            </div>
          </div>
          <div className="notes">
            Join us for our ceremony starting at 11:00 AM. Doors open at 10:30
            AM. All are welcome, including your little ones.
          </div>
          <FormControl component="fieldset" className="attendance">
            {guests.length &&
              guests.map((guest, index) => (
                <div key={index} className="guest">
                  <div className="name"> {guest.Name}</div>
                  <div className="button-group">
                    {rsvp.rsvpCeremony.options.map((option, optionIndex) => (
                      <Button
                        key={optionIndex}
                        variant={
                          guest[rsvp.rsvpCeremony.key] === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleChange(
                            { target: { value: option.value } },
                            index,
                            rsvp.rsvpCeremony.key
                          )
                        }
                        className={`form-button ${
                          guest[rsvp.rsvpCeremony.key] === option.value
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
          </FormControl>
        </>
      ),
    },
    {
      label: "Attending Reception",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.rsvpReception.label}
          </FormLabel>
          <div className="view">
            <img src={nac} alt="hello" className="ceremony-image" />
            <div className="details">
              <div className="title">National Art Center </div>
              <div className="address">
                1 Elgin Street, Ottawa, ON K1P 5W1 {""}
              </div>
              <div className="time"> 5:30 PM Doors Open</div>
            </div>
          </div>
          <div className="notes">
            We kindly request that only adults attend the reception.
          </div>

          <FormControl component="fieldset" className="attendance">
            {guests.length &&
              guests.map((guest, index) => (
                <div key={index} className="guest">
                  <div className="name"> {guest?.Name}</div>
                  <div className="button-group">
                    {rsvp.rsvpReception.options.map((option, optionIndex) => (
                      <Button
                        key={optionIndex}
                        variant={
                          guest[rsvp.rsvpReception.key] === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleChange(
                            { target: { value: option.value } },
                            index,
                            rsvp.rsvpReception.key
                          )
                        }
                        className={`form-button ${
                          guest[rsvp.rsvpReception.key] === option.value
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
          </FormControl>
        </>
      ),
    },
    {
      label: "Meal Choice",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.meal_selection.label}
          </FormLabel>
          <div className="meal-options-container">
            {rsvp.meal_selection.options.map((option, optionIndex) => (
              <Card key={optionIndex} className={`meal-option-tile `}>
                <img
                  src={option.image}
                  alt={option.label}
                  className="option-tile-image"
                />
                <div className="details">
                  <div className="name">{option.label}</div>
                  <div className="description">{option.description}</div>
                </div>
              </Card>
            ))}
          </div>

          <div className="notes">
            Click on one of the following images to set your meal option.
          </div>

          {guests.length &&
            guests.map((guest, index) =>
              guest.rsvpReception === rsvp.status.notAttending ? (
                <React.Fragment key={index}></React.Fragment>
              ) : (
                <FormControl
                  key={index}
                  component="fieldset"
                  className="attendance"
                >
                  <div className="guest">
                    <FormLabel component="legend" className="name">
                      {guest.Name}
                    </FormLabel>
                    <div className="meal-options">
                      {rsvp.meal_selection.options.map(
                        (option, optionIndex) => (
                          <div
                            key={optionIndex}
                            className={`meal-option ${
                              guest.mealChoice === option.value
                                ? "selected"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id={`mealChoice-${index}-${optionIndex}`}
                              name={`mealChoice-${index}`}
                              value={option.value}
                              checked={guest.mealChoice === option.value}
                              onChange={(event) =>
                                handleChange(event, index, "mealChoice")
                              }
                              className="hidden-radio"
                            />
                            <label
                              htmlFor={`mealChoice-${index}-${optionIndex}`}
                            >
                              <img
                                src={option.image}
                                alt={option.label}
                                className="option-image"
                              />
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </FormControl>
              )
            )}
        </>
      ),
    },
    {
      label: "Dietary Restrictions",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.dietary_restrictions.label}
          </FormLabel>
          <div className="notes">
            If no dietary restrictions, please leave blank.
          </div>
          <FormControl
            // key={index}
            className="diet-options-container attendance"
          >
            {guests.length &&
              guests.map((guest, index) =>
                guest.rsvpReception === rsvp.status.notAttending ? (
                  <></>
                ) : (
                  <div className="guest">
                    <FormLabel className="name">{guest?.Name}</FormLabel>
                    <Select
                      className="diet-options"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={guest?.diet}
                      label="Diet Restrictions"
                      onChange={(event) => handleChange(event, index, "diet")}
                    >
                      {rsvp.dietary_restrictions.options.map(
                        (option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                )
              )}
          </FormControl>
        </>
      ),
    },
    {
      label: "Advice to the Couple",
      content: (
        <>
          <FormLabel className="rsvp-subtitle">Advice to the Couple:</FormLabel>
          <div className="view">
            <img src={sheepFamily} alt="hello" className="advice-image" />
            <div className="details">
              <div className="title">
                What guidance would you offer us as we prepare for our wedding
                and marriage?{" "}
              </div>
              <div className="address">(Optional) </div>
            </div>
          </div>
          <FormControl className="attendance">
            <textarea
              className="text"
              rows={4}
              cols={50}
              value={guests.length > 0 ? guests[0].advice : ""}
              onChange={(event) => handleChange(event, 0, "advice")}
            />
          </FormControl>
        </>
      ),
    },
    {
      label: "Confirmation",
      content: (
        <div className="confirmation">
          <div className="title">Confirmation</div>
          <div className="description">
            Please review your responses before submitting. Please click the
            submit button.
          </div>
          <div className="guests">
            {guests.map((guest, index) => (
              <Card key={index} className="guest">
                <div className="name">{guest?.Name}</div>
                <div className="option">
                  Ceremony:{" "}
                  <Tag
                    type={`${
                      guest.rsvpCeremony === rsvp.status.attending
                        ? "green"
                        : "red"
                    }`}
                  >
                    {guest.rsvpCeremony}{" "}
                  </Tag>
                </div>
                <div className="option">
                  Reception:
                  <Tag
                    type={`${
                      guest.rsvpReception === rsvp.status.attending
                        ? "green"
                        : "red"
                    }`}
                  >
                    {guest.rsvpReception}
                  </Tag>
                </div>
                {isGuestBridalParty(guests) && guest?.rsvpRehearsal && (
                  <div className="option">
                    Rehearsal Dinner:
                    <Tag
                      type={`${
                        guest.rsvpRehearsal === rsvp.status.attending
                          ? "green"
                          : "red"
                      }`}
                    >
                      {guest.rsvpRehearsal}
                    </Tag>
                  </div>
                )}
                {guest?.rsvpReception === rsvp.status.attending && (
                  <div className="option">
                    Meal:{" "}
                    {getLabel(guest.mealChoice, rsvp.meal_selection.options)}
                  </div>
                )}
                {guest?.diet && (
                  <div className="option">
                    Dietary Restrictions:{" "}
                    {getLabel(guest.diet, rsvp.dietary_restrictions.options)}
                  </div>
                )}
              </Card>
            ))}
          </div>
        </div>
      ),
    },
  ];
  const bridalPartySteps = [
    {
      label: "Attending Ceremony",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.rsvpCeremony.label}
          </FormLabel>
          <div className="view">
            <img src={church} alt="hello" className="ceremony-image" />
            <div className="details">
              <div className="title">Notre Dame Cathedral Basilica </div>
              <div className="address">
                56 Guigues Ave, Ottawa, Ontario K1N 5H5{" "}
              </div>
              <div className="time"> 11:00 AM ceremony starts</div>
            </div>
          </div>
          <div className="notes">
            Join us for our ceremony starting at 11:00 AM. Doors open at 10:30
            AM. All are welcome, including your little ones.
          </div>
          <FormControl component="fieldset" className="attendance">
            {guests.length &&
              guests.map((guest, index) => (
                <div key={index} className="guest">
                  <div className="name"> {guest.Name}</div>
                  <div className="button-group">
                    {rsvp.rsvpCeremony.options.map((option, optionIndex) => (
                      <Button
                        key={optionIndex}
                        variant={
                          guest[rsvp.rsvpCeremony.key] === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleChange(
                            { target: { value: option.value } },
                            index,
                            rsvp.rsvpCeremony.key
                          )
                        }
                        className={`form-button ${
                          guest[rsvp.rsvpCeremony.key] === option.value
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
          </FormControl>
        </>
      ),
    },
    {
      label: "Attending Reception",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.rsvpReception.label}
          </FormLabel>
          <div className="view">
            <img src={nac} alt="hello" className="ceremony-image" />
            <div className="details">
              <div className="title">National Art Center </div>
              <div className="address">
                1 Elgin Street, Ottawa, ON K1P 5W1 {""}
              </div>
              <div className="time"> 5:30 PM Doors Open</div>
            </div>
          </div>
          <div className="notes">
            We kindly request that only adults attend the reception.
          </div>

          <FormControl component="fieldset" className="attendance">
            {guests.length &&
              guests.map((guest, index) => (
                <div key={index} className="guest">
                  <div className="name"> {guest?.Name}</div>
                  <div className="button-group">
                    {rsvp.rsvpReception.options.map((option, optionIndex) => (
                      <Button
                        key={optionIndex}
                        variant={
                          guest[rsvp.rsvpReception.key] === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleChange(
                            { target: { value: option.value } },
                            index,
                            rsvp.rsvpReception.key
                          )
                        }
                        className={`form-button ${
                          guest[rsvp.rsvpReception.key] === option.value
                            ? "selected"
                            : ""
                        }`}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
          </FormControl>
        </>
      ),
    },
    {
      label: "Meal Choice",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.meal_selection.label}
          </FormLabel>
          <div className="meal-options-container">
            {rsvp.meal_selection.options.map((option, optionIndex) => (
              <Card key={optionIndex} className={`meal-option-tile `}>
                <img
                  src={option.image}
                  alt={option.label}
                  className="option-tile-image"
                />
                <div className="details">
                  <div className="name">{option.label}</div>
                  <div className="description">{option.description}</div>
                </div>
              </Card>
            ))}
          </div>

          <div className="notes">
            Click on one of the following images to set your meal option.
          </div>

          {guests.length &&
            guests.map((guest, index) =>
              guest.rsvpReception === rsvp.status.notAttending ? (
                <React.Fragment key={index}></React.Fragment>
              ) : (
                <FormControl
                  key={index}
                  component="fieldset"
                  className="attendance"
                >
                  <div className="guest">
                    <FormLabel component="legend" className="name">
                      {guest.Name}
                    </FormLabel>
                    <div className="meal-options">
                      {rsvp.meal_selection.options.map(
                        (option, optionIndex) => (
                          <div
                            key={optionIndex}
                            className={`meal-option ${
                              guest.mealChoice === option.value
                                ? "selected"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id={`mealChoice-${index}-${optionIndex}`}
                              name={`mealChoice-${index}`}
                              value={option.value}
                              checked={guest.mealChoice === option.value}
                              onChange={(event) =>
                                handleChange(event, index, "mealChoice")
                              }
                              className="hidden-radio"
                            />
                            <label
                              htmlFor={`mealChoice-${index}-${optionIndex}`}
                            >
                              <img
                                src={option.image}
                                alt={option.label}
                                className="option-image"
                              />
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </FormControl>
              )
            )}
        </>
      ),
    },
    {
      label: "Dietary Restrictions",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.dietary_restrictions.label}
          </FormLabel>
          <div className="notes">
            If no dietary restrictions, please leave blank.
          </div>
          <FormControl
            // key={index}
            className="diet-options-container attendance"
          >
            {guests.length &&
              guests.map((guest, index) =>
                guest.rsvpReception === rsvp.status.notAttending ? (
                  <></>
                ) : (
                  <div className="guest">
                    <FormLabel className="name">{guest?.Name}</FormLabel>
                    <Select
                      className="diet-options"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={guest?.diet}
                      label="Diet Restrictions"
                      onChange={(event) => handleChange(event, index, "diet")}
                    >
                      {rsvp.dietary_restrictions.options.map(
                        (option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                )
              )}
          </FormControl>
        </>
      ),
    },
    {
      label: "Attending Rehearsal Dinner",
      content: (
        <>
          <FormLabel component="legend" className="rsvp-subtitle">
            {rsvp.rsvpRehearsal.label}
          </FormLabel>

          <div className="view">
            <img src={nac} alt="hello" className="ceremony-image" />
            <div className="details">
              <div className="title">1 Elgin Restaurant </div>
              <div className="address">1 Elgin Street, Ottawa, ON K1P 5W1 </div>
              <div className="time">
                {" "}
                Thursday, September 5th, 2024 at 6:30pm{" "}
              </div>
            </div>
          </div>
          <div className="notes">
            We kindly request that all memebers in the bridal party please plan
            to attend this event. Dinner to follow.
          </div>

          <FormControl component="fieldset" className="attendance">
            {guests.length &&
              guests.map((guest, index) => (
                <div key={index} className="guest">
                  <div className="name"> {guest.Name}</div>
                  <div className="button-group">
                    {rsvp.rsvpRehearsal.options.map((option, optionIndex) => (
                      <Button
                        key={optionIndex}
                        variant={
                          guest.rsvpRehearsal === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleChange(
                            { target: { value: option.value } },
                            index,
                            "rsvpRehearsal"
                          )
                        }
                        className={`form-button ${
                          guest.rsvpRehearsal === option.value ? "selected" : ""
                        }`}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
          </FormControl>
        </>
      ),
    },
    {
      label: "Advice to the Couple",
      content: (
        <>
          <FormLabel className="rsvp-subtitle">Advice to the Couple:</FormLabel>
          <div className="view">
            <img src={sheepFamily} alt="hello" className="advice-image" />
            <div className="details">
              <div className="title">
                What guidance would you offer us as we prepare for our wedding
                and marriage?{" "}
              </div>
              <div className="address">(Optional) </div>
            </div>
          </div>
          <FormControl className="attendance">
            <textarea
              className="text"
              rows={4}
              cols={50}
              value={guests.length > 0 ? guests[0].advice : ""}
              onChange={(event) => handleChange(event, 0, "advice")}
            />
          </FormControl>
        </>
      ),
    },
    {
      label: "Confirmation",
      content: (
        <div className="confirmation">
          <div className="title">Confirmation</div>
          <div className="description">
            Please review your responses before submitting. Please click the
            submit button.
          </div>
          <div className="guests">
            {guests.map((guest, index) => (
              <Card key={index} className="guest">
                <div className="name">{guest?.Name}</div>
                <div className="option">
                  Ceremony:{" "}
                  <Tag
                    type={`${
                      guest.rsvpCeremony === rsvp.status.attending
                        ? "green"
                        : "red"
                    }`}
                  >
                    {guest.rsvpCeremony}{" "}
                  </Tag>
                </div>
                <div className="option">
                  Reception:
                  <Tag
                    type={`${
                      guest.rsvpReception === rsvp.status.attending
                        ? "green"
                        : "red"
                    }`}
                  >
                    {guest.rsvpReception}
                  </Tag>
                </div>
                {isGuestBridalParty(guests) && guest?.rsvpRehearsal && (
                  <div className="option">
                    Rehearsal Dinner:
                    <Tag
                      type={`${
                        guest.rsvpRehearsal === rsvp.status.attending
                          ? "green"
                          : "red"
                      }`}
                    >
                      {guest.rsvpRehearsal}
                    </Tag>
                  </div>
                )}
                {guest?.rsvpReception === rsvp.status.attending && (
                  <div className="option">
                    Meal:{" "}
                    {getLabel(guest.mealChoice, rsvp.meal_selection.options)}
                  </div>
                )}
                {guest?.diet && (
                  <div className="option">
                    Dietary Restrictions:{" "}
                    {getLabel(guest.diet, rsvp.dietary_restrictions.options)}
                  </div>
                )}
              </Card>
            ))}
          </div>
        </div>
      ),
    },
  ];

  if (!isConfirmed) {
    return (
      <>
        <div className="rsvp-form-questions" netlify>
          {isGuestBridalParty(guests)
            ? bridalPartySteps[activeStep].content
            : steps[activeStep].content}

          <Box sx={{ mt: 2 }} className="form-buttons-container">
            {activeStep !== 0 && (
              <Button
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
                disabled={activeStep === 0}
                className="form-button"
              >
                <ArrowLeft />
                Back
              </Button>
            )}
            {(isGuestBridalParty(guests)
              ? activeStep !== bridalPartySteps.length - 1
              : activeStep !== steps.length - 1) && (
              <Button
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
                disabled={
                  isGuestBridalParty(guests)
                    ? activeStep === bridalPartySteps.length - 1
                    : activeStep === steps.length - 1
                }
                className="form-button"
              >
                Next
                <ArrowRight />
              </Button>
            )}
            <Button
              onClick={handleReset}
              sx={{ mt: 1, mr: 1 }}
              className="form-button"
            >
              <Reset />
              Reset
            </Button>
            {(isGuestBridalParty(guests)
              ? activeStep === bridalPartySteps.length - 1
              : activeStep === steps.length - 1) && (
              <>
                <Button
                  onClick={handleSubmit}
                  sx={{ mt: 1, mr: 1 }}
                  className="form-button"
                >
                  <Save />
                  Submit
                </Button>
              </>
            )}
          </Box>
        </div>
      </>
    );
  } else {
    return (
      <div className="final-message">
        <div className="title">Thank you for RSVPing </div>
        <div className="description">We can't wait to celebrate with you! </div>
        <img
          src={champagne}
          alt="champagne"
          title="champagne"
          className="final-img"
        />
        <div className="title">Mark your Calendars</div>
        <div
          className="subtitle"
          title="Stag & Doe meets Umbracha Pani blends Canadian and East Indian cultures in a festive pre-wedding event. Guests enjoy games, dances, and cuisine from both traditions, celebrating the couple's diverse heritage with joy and unity."
        >
          <ul>August 31st: Stag & Doe / Umbracha Pani</ul>
          <ul>September 7th: Wedding Day</ul>
        </div>
        {isGuestBridalParty(guests) ? (
          <AddToCalendarButton
            {...bridalPaartyCalendar}
            className="calendar"
          ></AddToCalendarButton>
        ) : (
          <AddToCalendarButton
            {...calendar}
            className="calendar"
          ></AddToCalendarButton>
        )}
        <br />

        <div className="subtitle">
          Looking for flight and hotel information for our wedding? Click the
          button below to explore exclusive promotions and discounts offered
          through our trusted partners.
          <Button variant="outlined">Flight & Hotels</Button>
        </div>
      </div>
    );
  }
};

export default Form;
