const { ButtonGroup, Button } = require("@mui/material");
const { homepage } = require("../../../config/PageConstants");
const { Link, useHref } = require("react-router-dom");

export const Buttons = () => {
  const href = useHref();

  return (
    <ButtonGroup className="button-group">
      {homepage.buttons
        .filter((button) => button.route !== href.substring(1))
        .map((button, index) => (
          <Link key={index} to={button.route}>
            <Button
              className="button"
              variant="contained"
              disabled={button.disabled}
            >
              {button.name}
            </Button>
          </Link>
        ))}
    </ButtonGroup>
  );
};

export const GoBackButton = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Button className="go-back-button" onClick={handleGoBack}>
      <span className="icon">←</span> Go Back
    </Button>
  );
};
