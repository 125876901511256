const API_BASE = "/api";

module.exports = {
  GUESTS: {
    getAll: { path: API_BASE + "/guests" },
    getSingle: { path: (guest) => API_BASE + `/guest/${guest}` },
  },
  INVITATIONS: {
    getAll: { path: API_BASE + "/invitations" },
    getSingle: { path: (invitation) => API_BASE + `/invitation/${invitation}` },
  },
};
