import React, { useState } from "react";
import { TextField } from "@mui/material";
import RequestService from "../../../services/Request.service";
import { guestRecord } from "../../../services/services";
import { Tile } from "@carbon/react";
import "./search.scss";

const SearchGuests = ({ setGuests }) => {
  const [searchName, setSearchName] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = async (event) => {
    setSearchName(event.target.value);
    const response =
      await RequestService.airtable.invitations.findGuestInvitation(
        event.target.value
      );

    setSearchResults(response);
  };

  return (
    <div className="search">
      <TextField
        label="Search Name"
        value={searchName}
        onChange={handleSearchChange}
        fullWidth
        className="search-input"
      />
      {searchResults.length > 0 && (
        <div className="search-results-title">
          Search Results
          {searchResults.map((search) => {
            return (
              <Tile
                className="search-results"
                onClick={() =>
                  setGuests(
                    guestRecord(search),
                    RequestService.localStorage.storeGuest(search)
                  )
                }
              >
                {search.fields["Name (from Invitees)"].map((name, index) => (
                  <div className="invitees">
                    {index > 0 && ","}{" "}
                    {index > 0 &&
                    index === search.fields["Name (from Invitees)"].length - 1
                      ? " & "
                      : ""}
                    {name}
                  </div>
                ))}
              </Tile>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchGuests;
