import React, { useState } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { Button } from "@mui/material";
import "./timeline.scss";
import { ArrowLeft } from "@carbon/icons-react";
import Slider from "react-slick";
import { church, logo_date, nac } from "../../../config/images";
import { details } from "../../../config/PageConstants";
import QA from "../Q&A/QA";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { calendar } from "../../../config/FormConstants";

const TimelinePage = () => {
  const [showCeremony, setShowCeremony] = useState(false);
  const [showReception, setShowReception] = useState(false);
  const scrollToDiv = (id) => {
    if (id === "ceremony") {
      setShowCeremony(true);
      setShowReception(false);
    } else if (id === "reception") {
      setShowCeremony(false);
      setShowReception(true);
    }
    const element = document.getElementById(id);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const targetScrollTop =
        elementRect.top +
        window.pageYOffset -
        viewportHeight / 2 +
        elementRect.height / 2;
      window.scrollTo({ top: targetScrollTop, behavior: "smooth" });
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const showTimelineAgain = () => {
    setShowCeremony(false);
    setShowReception(false);
  };

  return (
    <div className="details-content">
      <Tabs className="tab-buttons">
        <TabList aria-label="List of tabs">
          <Tab>Ceremony & Reception</Tab>
          <Tab>Q + A</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="timeline-tab">
            {(showCeremony || showReception) && (
              <Button onClick={showTimelineAgain}>
                <ArrowLeft /> Return to Timeline
              </Button>
            )}
            {showCeremony && (
              <div id="ceremony" className="event-section">
                <div className="event">
                  <div className="event-header">CEREMONY</div>
                  <div className="notes">
                    Join us for our ceremony starting at 11:00 AM. Doors open at
                    10:30 AM. All are welcome, including your little ones.
                  </div>
                  <div className="event-location">
                    <div className="location-image-container">
                      <img
                        className="location-image"
                        src={church}
                        alt="church"
                      />
                    </div>
                    <div className="location-details">
                      <div className="location">
                        <div className="event-address">
                          Notre Dame Cathedral Basilica
                        </div>
                        <div className="event-body">
                          56 Guigues Ave, Ottawa, Ontario K1N 5H5
                        </div>
                        <div className="event-time">11:00 AM</div>
                        <iframe
                          className="event-map"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11199.91835362334!2d-75.696196!3d45.4299128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04fd20846a5b%3A0xffbf20984ca0d742!2sNotre%20Dame%20Cathedral%20Basilica!5e0!3m2!1sen!2sca!4v1712073103365!5m2!1sen!2sca"
                          width="600"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          alt="hello"
                          title="Notre Dame Cathedral Basilica"
                        ></iframe>
                      </div>
                      <div>
                        <div className="parking event-details">
                          <div className="label-header">
                            Parking Information
                          </div>
                          <div className="label-description">
                            All parking spots delineated by blue lines are for
                            the exclusive use of Cathedral visitors (mass,
                            prayer, parish office, Café des Tours, etc). The
                            yellow parking spots are for paying customers but
                            may be used free of charge if all the blue spots are
                            taken. All parking spots are free for weekend
                            masses, from ½ hour before to ½ hour after mass.
                          </div>
                        </div>
                        <div className="dress-code event-details">
                          <div className="label-header">Dress Code</div>
                          <div className="label-description">
                            We kindly ask you to wear your Sunday's best or
                            formal attire for our wedding ceremony at Notre Dame
                            Cathedral. As this is a sacred and revered space, we
                            encourage guests to dress respectfully for the
                            occasion. Your elegant attire will add to the
                            solemnity and beauty of this special day. We look
                            forward to celebrating with you in style.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showReception && (
              <div id="reception" className="event-section">
                <div className="event">
                  <div className="event-header">RECEPTION</div>
                  <div className="notes">
                    We kindly request that only adults attend the reception.
                  </div>
                  <div className="event-location">
                    <div className="location-image-container">
                      <img className="location-image" src={nac} alt="nac" />
                    </div>
                    <div className="location-details">
                      <div className="location">
                        <div className="event-address">
                          National Art Center - Canada Room
                        </div>
                        <div className="event-body">
                          1 Elgin St, Ottawa, ON K1P 5W1
                        </div>
                        <div className="event-time">11:00 AM</div>
                        <iframe
                          className="event-map"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.3084327632664!2d-75.69595702317407!3d45.423283536167254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce050023827aa7%3A0x1bea53c995adfada!2sNational%20Arts%20Centre!5e0!3m2!1sen!2sca!4v1712074293182!5m2!1sen!2sca"
                          width="600"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          title="National Art Center"
                        ></iframe>
                      </div>
                      <div>
                        <div className="parking event-details">
                          <div className="label-header">
                            Parking Information
                          </div>
                          <div className="label-description">
                            <p>
                              The Elgin/Slater parking garage entrance is open
                              at all times. Automated payment stations are
                              available 24 hours, 7 days a week. The clearance
                              for the parking garage is 6'4".
                            </p>
                            <p>
                              <strong>
                                Parking attendants are on duty at the following
                                times:
                              </strong>
                            </p>
                            <ul>
                              <li>- Saturday, 8 am to 1 am</li>
                            </ul>
                            <br />
                            <p>
                              The NAC garage parking rate is $3 per half hour.
                            </p>

                            <p>
                              <ul>
                                <li>
                                  {" "}
                                  <strong>Enter before 4 pm</strong>: $3 per
                                  half hour, $22 maximum
                                </li>
                                <li>
                                  {" "}
                                  <strong>Enter after 4 pm</strong>: $3 per half
                                  hour, $12 maximum
                                </li>
                                <li>
                                  {" "}
                                  <strong>24-hour period</strong>: $3 per half
                                  hour, $30 maximum
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        <div className="dress-code event-details">
                          <div className="label-header">Dress Code</div>
                          <div className="label-description">
                            Please note that the dress code for the wedding is
                            formal. We kindly request that our guests wear
                            formal gowns and suits. Your elegant attire will
                            contribute to the ambiance of this special occasion.
                            We look forward to celebrating in style with you.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!(showCeremony || showReception) && (
              <>
                <div className="timeline-tab-container">
                  <div className="timeline">
                    <div className="event">
                      <div className="event-header">CEREMONY</div>
                      <div className="event-location">
                        <div className="location-image-container">
                          <img
                            className="location-image"
                            src={church}
                            alt="church"
                          />
                        </div>
                        <div className="location-details">
                          <div className="event-address">
                            Notre Dame Cathedral
                          </div>
                          <div className="event-body">56 Guigues Ave</div>
                          <div>
                            <div className="event-time">11:00 AM</div>

                            <Button
                              variant="outlined"
                              onClick={() => scrollToDiv("ceremony")}
                            >
                              Click for more details
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-line"></div>
                    <div className="event">
                      <div className="event-header"></div>
                      <div className="event-location">
                        <div className="location-details">
                          <div className="event-address"></div>
                          <div className="event-body">
                            After the ceremony, feel free to explore the area,
                            or relax until the evening celebration begins.
                          </div>
                          <div className="event-time"></div>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-line"></div>
                    <div className="event">
                      <div className="event-header">RECEPTION</div>
                      <div className="event-location">
                        <div className="location-image-container">
                          <img
                            className="location-image"
                            src={nac}
                            alt="church"
                          />
                        </div>
                        <div className="location-details">
                          <div className="event-address">
                            National Art Center - Canada Room
                          </div>
                          <div className="event-body">
                            1 Elgin St, Ottawa, ON K1P 5W1
                          </div>
                          <div className="event-time">5:30 PM</div>
                          <Button
                            variant="outlined"
                            onClick={() => scrollToDiv("reception")}
                          >
                            Click for more details
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel className="faqs-tab">
            {/* <div className="faqs">
              <Slider {...settings}>
                {details.faqs.map((question) => {
                  return <QA {...question} />;
                })}
              </Slider>
            </div> */}

            <div className="faqs">
              <Slider {...settings}>
                {details.faqs.map((question, index) => (
                  <div key={index} className="qa-slide">
                    <QA {...question} />
                  </div>
                ))}
              </Slider>
            </div>
            <img src={logo_date} className="logo" title="logo" alt="logo" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default TimelinePage;
