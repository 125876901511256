import { SET_GUEST } from "./types.js";

const GuestReducer = (state, action) => {
  switch (action.type) {
    case SET_GUEST: {
      return {
        ...state,
        guests: action.payload,
      };
    }
    default:
      return state;
  }
};
export default GuestReducer;
