import React, { useEffect, useState } from "react";
import "../../scss/web/pages.scss";
import "../../scss/mobile/mobile.scss";
import { Tab, TabList, TabPanel, TabPanels, Tabs, Tile } from "@carbon/react";
import {
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { homepage, ourStory, wedding } from "../../config/PageConstants";
import { differenceInCalendarDays } from "date-fns";
import RSVPForm from "../../components/form/RSVPForm";
import { Buttons } from "../../components/shared/Buttons/Buttons";
import { rsvpRoute } from "../../config/routes";
import { champagne } from "../../config/images";
import TimelinePage from "../../components/shared/Timeline/TimelinePage";
import { HeaderDiv } from "../../components/shared/SharedComponents";
import TravelTabs from "../../components/shared/Travel/TravelTabs";
import { Link, useParams } from "react-router-dom";

const Homepage = () => {
  const images = homepage?.slideshow?.images;

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <Grid container className="homepage">
      <div className="slideshow-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentImage ? "active" : ""}`}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/${image})`,
            }}
          >
            <div className="homepage-content">
              <div className="overlay-container">
                <Tile className="wedding-info">
                  <div className="title">{wedding.names}</div>
                  <div className="subtitle">{wedding.date}</div>

                  <div className="subtitle">
                    {differenceInCalendarDays(wedding.utc_date, new Date())}{" "}
                    {homepage.countdownText}
                  </div>
                </Tile>

                <Buttons />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Grid>
  );
};

const OurStory = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextStory = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ourStory.stories.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextStory, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="our-story">
      <div className="story-card">
        <div className="story-header">
          <h2 className="title">
            <span className="dot" /> Our Story <span className="dot" />
          </h2>
        </div>
        <div className="timeline">
          {ourStory.stories.map((story, index) => (
            <div
              key={index}
              className={`story ${
                story.position === "right" && "side-switch"
              } ${currentIndex === index && "active"}`}
            >
              {story.image && (
                <img src={story.image} alt="hello" className="image" />
              )}
              <div className="story-details">
                {story.headers && (
                  <div className="headers">{story.headers}</div>
                )}
                {story.text && story.date && story.location && (
                  <>
                    <div className="text">{story.text}</div>
                    <div className="location">{story.location}</div>
                    <div className="date">{story.date} </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// const TheCrew = () => {};
// const MusicRecommendations = () => {};

const SaveTheDate = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // // useEffect(() => {
  // //   const intervalId = setInterval(() => {
  // //     setCurrentIndex((prevIndex) => (prevIndex + 1) % ourStory.stories.length);
  // //   }, 6000);

  // //   return () => {
  // //     clearInterval(intervalId);
  // //   };
  // }, []);

  return (
    <div className="our-story">
      <div className="story-card">
        <div className="story-header">
          <h2 className="title">
            <span className="dot" /> Our Story <span className="dot" />
          </h2>
        </div>
        {/* <CardWrapper>
          {ourStory.stories.map((story, index) => (
            <Card className="timeline" preventDefaultTouchmoveEvent>
              <div key={index}>
                <img src={story.image} alt="hello" />

                <div className="story-details">
                  {story.headers && (
                    <div className="headers">{story.headers}</div>
                  )}
                  {story.text && story.date && story.location && (
                    <>
                      <div className="text">{story.text}</div>
                      <div className="location">{story.location}</div>
                      <div className="date">{story.date} </div>
                    </>
                  )}
                </div>
              </div>
            </Card>
          ))}
        </CardWrapper> */}
        ;
      </div>
    </div>
  );
};

const Invitation = () => {
  const { invitationId } = useParams();

  return (
    <div className="invitation-page">
      <iframe
        className="invitation-frame"
        src="https://simplebooklet.com/copyofgatefoldtemplate10x"
        alt="inviation"
        title="Analeeze & Jack Virtual Invitation"
      />
      <ButtonGroup>
        <Link key={"homepage"} to={homepage.buttons[0].route}>
          <Button
            className="button"
            variant="contained"
            disabled={homepage.buttons[0].disabled}
          >
            {homepage.buttons[0].name}
          </Button>
        </Link>

        <Link
          key={"homepage"}
          to={
            invitationId
              ? `${homepage.buttons[1].route}/${invitationId}`
              : homepage.buttons[1].route
          }
        >
          <Button
            className="button"
            variant="contained"
            disabled={homepage.buttons[1].disabled}
          >
            {homepage.buttons[1].name}
          </Button>
        </Link>
        <Link key={"homepage"} to={homepage.buttons[2].route}>
          <Button
            className="button"
            variant="contained"
            disabled={homepage.buttons[2].disabled}
          >
            {homepage.buttons[2].name}
          </Button>
        </Link>
        <Link key={"homepage"} to={homepage.buttons[3].route}>
          <Button
            className="button"
            variant="contained"
            disabled={homepage.buttons[3].disabled}
          >
            Travel
          </Button>
        </Link>
      </ButtonGroup>
    </div>
  );
};

const RSVP = () => {
  return (
    <div container className="rsvp">
      <div className="slideshow-container">
        <img src={champagne} alt="kissing" className="rsvp-image" />
      </div>
      <div className="rsvp-form-container">
        <Tile className="wedding-info">
          <RSVPForm />
        </Tile>{" "}
        <Buttons />
      </div>
    </div>
  );
};

const BridalParty = () => {
  return <div className="bridal-party"></div>;
};

const BridalPartyPortal = () => {
  return <div className="bridal-party-portal"></div>;
};

const Admin = () => {
  const [guests, setGuests] = useState();
  const [invitations, getInvitations] = useState();
  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    {
      id: "guestType",
      label: "Type of guest",
      minWidth: 100,
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "rsvpStatus", label: "RSVP Status", minWidth: 100 },
    { id: "mealChoice", label: "Meal Choice", minWidth: 100 },
    { id: "foodAllergy", label: "Allergies", minWidth: 100 },
    { id: "invitationId", label: "Invitation IDs", minWidth: 100 },
  ];

  if (guests)
    return (
      <div className="admin">
        {" "}
        Admin Page
        <div>Wedding Guest List</div>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <bold>{column.label}</bold>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {guests.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() =>
                            column.id === "invitationId" &&
                            window.open(`#${rsvpRoute}/${value}`, "_blank")
                          }
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  else return <></>;
};

const Details = () => {
  return (
    <div className="details-container">
      <div className="details">
        <HeaderDiv
          title={"The Details"}
          subtitle={" Everything you need to be ready to party!"}
          date={
            " Explore the following to discover more details about each event."
          }
        />
        <TimelinePage />

        <Buttons />
      </div>
    </div>
  );
};
const TravelStay = () => {
  return (
    <div className="details-container">
      <div className="details">
        <HeaderDiv
          title={"Travel & Stay"}
          subtitle={"Find everything you need for your trip to Ottawa"}
          date={
            "Take advantage of exclusive discounts with our partnered airlines and hotels"
          }
        />
        <div className="details-content">
          <TravelTabs />
        </div>
      </div>
      <Buttons />
    </div>
  );
};

export {
  Homepage,
  OurStory,
  SaveTheDate,
  RSVP,
  BridalParty,
  BridalPartyPortal,
  Details,
  Admin,
  TravelStay,
  Invitation,
};
