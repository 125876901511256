const baseRoute = "/";
module.exports = {
  baseRoute: baseRoute,
  saveTheDateRoute: `${baseRoute}save-the-date`,
  rsvpRoute: `${baseRoute}rsvp`,
  ourStoryRoute: `${baseRoute}our-story`,
  detailsRoute: `${baseRoute}details`,
  travelRoute: `${baseRoute}travel`,
  musicRoute: `${baseRoute}music`,
  adminRoute: `${baseRoute}admin`,
  invitationRoute: `${baseRoute}invitation`,
  errorRoutes: "*",
};
