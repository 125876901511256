import "./qa.scss";

const QA = ({ question = "What is my name", answer = " analeeze", image }) => {
  return (
    <div className="qa-container">
      <div className="qa">
        <div className="question">{question}</div>
        <div className="answer-container">
          {/* {image && (
            <div className="answer-image">
              <img src={image} alt={image} />
            </div>
          )} */}
          <div className="answer">{answer}</div>
        </div>
      </div>
    </div>
  );
};
export default QA;
