import React, { useEffect, useState } from "react";
import "./rsvp-form.scss";
import { useParams } from "react-router-dom";
import Form from "./Form";
import SearchGuests from "../shared/Search/SearchGuests";
import RequestService from "../../services/Request.service";
import { ArrowLeft } from "@carbon/icons-react";
import { Button } from "@mui/material";
import { HeaderDiv } from "../shared/SharedComponents";

const RSVPForm = () => {
  const [guests, setGuests] = useState([]);
  const { invitationId } = useParams();

  useEffect(() => {
    if (invitationId) {
      (async () => {
        const response =
          await RequestService.airtable.invitations.searchInvitation(
            invitationId
          );
        setGuests(response);
        RequestService.localStorage.storeGuest(response);
      })();
    } else {
      const storedGuests = RequestService.localStorage.retrieveGuest();
      if (storedGuests.length) setGuests(storedGuests);
    }
  }, [invitationId]);

  return (
    <>
      <div className="rsvp-form">
        {guests.length > 0 && (
          <Button onClick={() => setGuests([])}>
            {" "}
            <ArrowLeft /> Go back{" "}
          </Button>
        )}

        <HeaderDiv
          title={"RSVP"}
          subtitle={
            guests.length <= 0 && "Looking forward to celebrating with you."
          }
          date={
            guests.length <= 0 && "Your reply is requested by July 15th, 2024"
          }
        />

        {guests.length > 0 ? (
          <Form guests={guests} setGuests={setGuests} />
        ) : (
          <SearchGuests setGuests={setGuests} />
        )}
      </div>
    </>
  );
};

export default RSVPForm;
