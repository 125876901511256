const {
  forehead_kiss,
  sitting_cliffs,
  proposal,
  watercolor_proposal,
  watercolor_church,
  watercolor_downtown,
  young_jack,
  young_analeeze,
  gatineau,
  walking,
  butterflykiss,
  weddingShow,
  weddingShowBW,
  cowboy,
  indianEngagement,
  sheepFamily,
  beachDay,
  camping,
  axeThrowing,
  kissing,
} = require("./images");
const { baseRoute, rsvpRoute, detailsRoute, travelRoute } = require("./routes");

const hash = "#";
module.exports = {
  wedding: {
    names: "Analeeze & Jack",
    date: "September 7th, 2024",
    utc_date: new Date("2024-09-07"),
  },
  hashRoutes: {
    baseRoute: `${hash}/${baseRoute}`,
  },
  header: {
    buttons: [
      {
        name: "Homepage",
        route: baseRoute,
      },
    ],
  },
  homepage: {
    countdownText: "days away!",
    slideshow: {
      images: [
        forehead_kiss,
        gatineau,
        sitting_cliffs,
        walking,
        proposal,
        butterflykiss,
      ],
    },
    buttons: [
      {
        name: "Homepage",
        route: baseRoute,
        disabled: false,
      },
      // {
      //   name: "Our Story",
      //   route: ourStoryRoute,
      //   disabled: false,
      // },
      {
        name: "RSVP",
        route: rsvpRoute,
        disabled: false,
      },
      {
        name: "Details",
        route: detailsRoute,
        disabled: false,
      },
      {
        name: "Travel & Stay",
        route: travelRoute,
        disabled: false,
      },
      // {
      //   name: "Wedding Party",
      //   route: musicRoute,
      //   disabled: false,
      // },
    ],
  },

  details: {
    tiles: [
      {
        key: "ceremony",
        name: "ceremony",
        label: "Ceremony & Reception",
        route: `${detailsRoute}#ceremony`,
        image:
          "https://i.pinimg.com/736x/70/1c/ca/701ccadce0ade1b5d61fcfb3503b4e9f.jpg",
        information:
          "September 7th is a very important date and you don't want to be late! Get all of the day-of details here.",
      },
      {
        key: "travel",
        name: "travel",
        label: "Travel & Stay",
        route: `${detailsRoute}#travel`,
        image: "ceremony,reception",
        information:
          "We plan to celebrate the future Mr. and Mrs. Dewan all night long, so you may want to book a room.",
      },
      {
        key: "attractions",
        name: "attractions",
        label: "Nearby Attractions",
        route: `${detailsRoute}#attractions`,
        image: "ceremony,reception",
        information:
          "If you're in town and have the itch to stay awhile, check out our favorite local spots.",
      },
    ],
    faqs: [
      // {
      //   question: "WHEN SHOULD I RSVP?",
      //   answer:
      //     "If you are able to RSVP when you receive your invitation -that is great and very helpful! The last day to RSVP is May 1st, 2024. If we do not get an RSVP back by the date provided, it will be marked as a 'No'. We will miss you celebrating with us, however, we have to provide total guest counts to our vendors in a timely manner. Due to this we cannot accept late RSVP's, we hope that you understand!",
      // },
      // {
      //   question: "CAN I BRING A DATE/ +1?",
      //   answer:
      //     "Due to the limited space, we are only able to accommodate those guests formally invited on your wedding invitation. If you received a plus one they will appear under your name when you RSVP. Thank you for understanding!",
      // },
      {
        question: "ARE KIDS INVITED?",
        answer:
          "Invitations will be specific and will include the names of the children who are invited. Due to space, we are only able to accommodate the children in our wedding party. We hope you understand & can enjoy a fun date night!",
      },
      {
        question: "WHAT TIME SHOULD I ARRIVE AT THE CEREMONY?",
        answer:
          "We recommend that you arrive at least 20-30 minutes before our 11:00am ceremony time. This gives us enough time to be on schedule. If you are late and the wedding has started, we ask you stand in the back until the ceremony is finished and then join us at the reception! ",
      },
      // {
      //   question:
      //     "ARE THE CEREMONY AND RECEPTION TAKING PLACE AT THE SAME LOCATION?",
      //   answer:
      //     "No! The ceremony and reception will take place at two different locations. However they are in walkable distance to each other",
      //   image: kissing,
      // },
      // {
      //   question: "IS THERE PARKING ONSITE? CAN I LEAVE MY CAR OVERNIGHT?",
      //   answer:
      //     "Yes! There is underground parking at the NAC allowing plenty of room for our guests to park. Please don't drink and drive. The venue allows cars to stay parked overnight as long as they are picked up the next.",
      // },
      {
        question: "WHAT SHOULD I WEAR?",
        answer:
          "We would love to see our family and friends dress up with us! We are requesting formal attire. Feel free to reach out with any questions!",
      },
      {
        question: "ARE THERE ANY COLORS THAT SHOULD BE AVOIDED?",
        answer:
          "We kindly ask that you avoid colors traditional reserved for the bride - white, ivories, and creams.",
      },
      {
        question:
          "IS IT OKAY TO TAKE PICTURES W/ OUR PHONES DURING THE CEREMONY?",
        answer:
          "We are having an unplugged ceremony. We hired 2 amazing photographers to capture all special moments. However, please feel free to take photos throughout the evening otherwise! We will be utilizing a program called Confetti so that you can share your photos with us. A photo booth will also be present! If you post, please tag us :)",
      },
      {
        question: "WILL THERE BE AN OPEN BAR?",
        answer:
          "Yes! Drinks on us, hangover on you! Please drink responsibly and carpool/Uber if needed! Kindly bring a valid ID",
      },
      {
        question: "WILL IT BE HOT?",
        answer:
          "Yes! Summers in Canada can be brutal the temperature in Ottawa during the time of our wedding is estimated a High of +77°F/+25°C and a low of +66°F/+19°C. Both the ceremony and reception will be indoors.",
      },
    ],
  },
  rsvp: {
    slideshow: {
      images: [
        // proposal,
        // walking,
        beachDay,
        // sitting_cliffs,
        weddingShowBW,
        cowboy,
        // kissing,
        sheepFamily,
        indianEngagement,
        axeThrowing,
        weddingShow,
        // christmasParty,
        camping,
        forehead_kiss,
      ],
    },
  },
  // ourStory: {
  //   headers: {
  //     subtitle: "Let us take you on a journey through our love story...",
  //   },
  //   stories: [
  //     {
  //       headers: "Let us take you on a journey through our love story...",
  //     },
  //     {
  //       image: young_jack,
  //       text: "Jack Dewan was ",
  //       position: "left",
  //       location: "Osgoode, Canada",
  //       date: "December 3, 1997",
  //     },
  //     {
  //       image: young_analeeze,
  //       text: "Analeeze Mendonsa was ",
  //       position: "left",
  //       location: "Mumbai, India",
  //       date: "March 30, 1995",
  //     },
  //     {
  //       image: watercolor_downtown,
  //       text: "Our very first date was a leisurely stroll through the heart of downtown Ottawa, a memorable day that marked the inception of our extraordinary relationship.",
  //       position: "left",
  //       location: "Downtown Ottawa, Canada",
  //       date: "March 21, 2021",
  //     },
  //     {
  //       text: "Fast forward to September 5, 2023, when Jack knelt down and proposed amidst the breathtaking Cliffs of Moher in Ireland",
  //       image: watercolor_proposal,
  //       position: "right",
  //       location: "Cliffs of Moher, Ireland",
  //       date: "September 5, 2023",
  //     },
  //     {
  //       image: watercolor_church,
  //       text: "Now, as we come full circle, we've chosen to exchange our vows in the place where our remarkable journey began.",
  //       position: "left",
  //       location: "Downtown Ottawa, Canada",
  //       date: "September 7, 2024",
  //     },
  //   ],
  // },
  ourStory: {
    headers: {
      subtitle: "Let us take you on a journey through our love story...",
    },
    stories: [
      {
        headers: "Let us take you on a journey through our love story...",
      },
      {
        image: young_jack,
        text: "Jack, a true Ottawan, spent his entire childhood in the charming town of Osgoode. From backyard adventures to high school milestones, Osgoode is where his roots run deep.",
        position: "left",
        location: "Osgoode, Canada",
        date: "December 3, 1997",
      },
      {
        image: young_analeeze,
        text: "Analeeze's story begins in India, where she spent her early years before embarking on a transformative journey. At the age of 10, she moved to Canada, experiencing the diverse landscapes of Vancouver, the cozy charm of Burlington, the warmth of Texas, and finally, finding a home in Ottawa in 2019. ",
        position: "left",
        location: "Mumbai, India",
        date: "March 30, 1995",
      },
      {
        image: watercolor_downtown,
        text: "Despite the distance, their paths converged, and their love story began, proving that destiny has a way of bringing two hearts together, no matter the miles between them. Our very first date was a leisurely stroll through the heart of downtown Ottawa, a memorable day that marked the inception of our extraordinary relationship.",
        position: "left",
        location: "Downtown Ottawa, Canada",
        date: "March 21, 2021",
      },
      {
        image: watercolor_proposal,
        text: "Fast forward to September 5, 2023, when Jack knelt down and proposed amidst the breathtaking Cliffs of Moher in Ireland",
        position: "right",
        location: "Cliffs of Moher, Ireland",
        date: "September 5, 2023",
      },
      {
        image: watercolor_church,
        text: "Now, as we come full circle, we've chosen to exchange our vows in the place where our remarkable journey began.",
        position: "left",
        location: "Downtown Ottawa, Canada",
        date: "September 7, 2024",
      },
    ],
  },
};
