import "./shared.scss";
export const HeaderDiv = ({ title, subtitle, date }) => {
  return (
    <div className="header">
      <div className="title">{title}</div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
      {date && <div className="date">{date}</div>}
    </div>
  );
};
