module.exports = {
  couple: "../images/couple.png",
  logo_date: "../images/logo_date.png",
  logo: "../images/logo.png",
  deckle_paper: "../images/decklepaper.png",
  sitting_cliffs: "../images/sitting-cliffs.png",
  forehead_kiss: "../images/foreheadkiss.png",
  proposal: "../images/proposal.png",
  watercolor_downtown: "../images/downtown-watercolor.png",
  watercolor_church: "../images/church-watercolor.png",
  watercolor_proposal: "../images/proposal-watercolor.png",
  young_jack: "../images/IMG_8213.png",
  young_analeeze: "../images/IMG_5977.png",
  gatineau: "../images/gatineau.jpeg",
  walking: "../images/walking.jpeg",
  kissing: "../images/kissing.jpeg",
  butterflykiss: "../images/butterflykiss.jpeg",
  weddingShow: "../images/wedding-show.png",
  weddingShowBW: "../images/wedding-show-bw.jpeg",
  cowboy: "../images/cowboy.jpeg",
  indianEngagement: "../images/indian-engagement.jpeg",
  sheepFamily: "../images/sheep-family.jpeg",
  beachDay: "../images/beach-day.jpeg",
  christmasParty: "../images/christmas-party.heic",
  camping: "../images/camping.jpeg",
  axeThrowing: "../images/axe-throwing.jpeg",
  champagne: "../images/champagne.jpg",
  church: "../images/church.png",
  nac: "../images/nac.png",
  fish: "../images/mealChoice/fish.png",
  beef: "../images/mealChoice/beef.png",
  veg: "../images/mealChoice/veg.png",
  lord_elgin: "../images/elgin.png",
};
