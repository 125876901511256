import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

export const guestRecord = (record) => {
  if (!record || !record.fields || !record.fields.Invitees) {
    return [];
  }

  return record.fields.Invitees.map((id, index) => ({
    Name: record.fields["Name (from Invitees)"]?.[index] || "",
    rsvpCeremony: record.fields.rsvpCeremony?.[index] || "",
    rsvpReception: record.fields.rsvpReception?.[index] || "",
    rsvpRehearsal: record.fields.rsvpRehearsal?.[index] || "",
    mealChoice: record.fields.mealChoice?.[index] || "",
    diet: record.fields.diet?.[index] || "",
    advice: record.fields.advice?.[index] || "",
    guestId: id,
    guestType: record.fields["Type of Guest (from Invitees)"]?.[index] || "",
  }));
};

export const calculateTimeDifference = (
  targetTime,
  currentTime = new Date()
) => {
  const targetDate = new Date(targetTime);

  const remainingDays = differenceInDays(targetDate, currentTime);
  const remainingHours = differenceInHours(targetDate, currentTime) % 24;
  const remainingMinutes = differenceInMinutes(targetDate, currentTime) % 60;
  const remainingSeconds = differenceInSeconds(targetDate, currentTime) % 60;

  let statement = "";

  if (remainingDays > 0) {
    statement += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;
  }

  if (remainingHours > 0 || statement !== "") {
    statement += `${remainingHours} hour${remainingHours > 1 ? "s" : ""} `;
  }

  if (remainingMinutes > 0 || statement !== "") {
    statement += `${remainingMinutes} minute${
      remainingMinutes > 1 ? "s" : ""
    } `;
  }

  statement += `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;

  return statement.trim();
};
