import { Route, Routes } from "react-router-dom";
import {
  Homepage,
  RSVP,
  Details,
  TravelStay,
  Invitation,
} from "../content/Pages/pages";
import {
  baseRoute,
  errorRoutes,
  rsvpRoute,
  detailsRoute,
  travelRoute,
  invitationRoute,
} from "../config/routes";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path={baseRoute} element={<Homepage />} />
        {/* <Route path={saveTheDateRoute} element={<SaveTheDate />} /> */}
        {/* <Route path={ourStoryRoute} element={<OurStory />} /> */}
        <Route path={rsvpRoute} element={<RSVP />} />
        <Route path={invitationRoute} element={<Invitation />} />
        <Route
          path={`${invitationRoute}/:invitationId`}
          element={<Invitation />}
        />
        <Route path={`${rsvpRoute}/:invitationId`} element={<RSVP />} />
        {/* <Route path={saveTheDateRoute} element={<SaveTheDate />} /> */}
        <Route path={detailsRoute} element={<Details />} />
        <Route path={travelRoute} element={<TravelStay />} />

        {/* <Route path={adminRoute} element={<Admin />} /> */}
        <Route path={errorRoutes} element={<Homepage />} />
      </Routes>
    </>
  );
};
export default Router;
