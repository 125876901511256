import "./layout.scss";
import { logo } from "../../config/images";
import { Header, HeaderGlobalBar, HeaderName } from "@carbon/react";
import { Button, ButtonGroup } from "@mui/material";
import { baseRoute } from "../../config/routes";
import { header } from "../../config/PageConstants";
import { Link } from "react-router-dom";
import { calculateTimeDifference } from "../../services/services";
import { useEffect, useState } from "react";

const AppHeader = () => {
  const [countdown, setCountdown] = useState(
    calculateTimeDifference(new Date(2024, 9, 7, 22, 0))
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedCountdown = calculateTimeDifference(
        new Date(2024, 9, 7, 22, 0)
      );
      setCountdown(updatedCountdown);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="header">
      <Header aria-label="Analeeze & Jack">
        <HeaderName href={baseRoute} className="header-name">
          <img className="site-logo" alt="Analeeze & Jack" src={logo} />
          Analeeze & Jack
        </HeaderName>

        <div className="countdown">{countdown}</div>

        <HeaderGlobalBar>
          <ButtonGroup className="button-group">
            {header.buttons.map((button) => (
              <Link to={button.route} key={button.name}>
                <Button className="button" variant="contained">
                  {button.name}
                </Button>
              </Link>
            ))}
          </ButtonGroup>
        </HeaderGlobalBar>
      </Header>
    </div>
  );
};

const AppFooter = () => {
  return (
    <div className="footer">
      Designed by Jack Dewan and Developed by Analeeze Mendonsa{" "}
    </div>
  );
};

export { AppHeader, AppFooter };
