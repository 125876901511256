import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { Button, ButtonGroup } from "@mui/material";
import { lord_elgin } from "../../../config/images";
import "./travel-tab.scss";

const TravelTabs = () => {
  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const targetScrollTop =
        elementRect.top +
        window.pageYOffset -
        viewportHeight / 2 +
        elementRect.height / 2;
      window.scrollTo({ top: targetScrollTop, behavior: "smooth" });
    }
  };
  return (
    <Tabs className="tab-buttons">
      <TabList aria-label="List of tabs">
        <Tab>Flights</Tab>
        <Tab>Hotels</Tab>
      </TabList>
      <TabPanels className="travel-container">
        <TabPanel className="flight-container">
          <div className="flight-information">
            <div className="event-section">
              <div className="event">
                <div className="event-header">
                  We have partnered with two flight companies
                  <ButtonGroup className="event-buttons">
                    <Button onClick={() => scrollToDiv("airport")}>
                      Airport Information
                    </Button>
                    <Button onClick={() => scrollToDiv("air-canada")}>
                      Air Canada
                    </Button>
                    <Button onClick={() => scrollToDiv("united")}>
                      United
                    </Button>
                  </ButtonGroup>
                </div>

                <div className="event-location" id="airport">
                  <div className="location-image-container">
                    <img
                      className="location-image"
                      src={
                        "https://www.shutterstock.com/image-illustration/view-terminal-takeoff-field-airplane-600nw-1202334232.jpg"
                      }
                      alt="nac"
                    />
                  </div>
                  <div className="location-details">
                    <div className="location">
                      <div className="event-address">
                        Airport Information
                        <div className="event-body">
                          <p>
                            {" "}
                            For travelers heading to Ottawa, it's important to
                            know about the airports serving the region,
                            primarily Ottawa Macdonald-Cartier International
                            Airport (YOW) and Montréal-Pierre Elliott Trudeau
                            International Airport (YUL).
                          </p>
                          <br />
                          <p>
                            For travelers flying to Ottawa, it's advisable to
                            check for flights directly to Ottawa
                            Macdonald-Cartier International Airport (YOW) for
                            the most convenient access to the city. However, if
                            you're considering flights to Montréal-Pierre
                            Elliott Trudeau International Airport (YUL) due to
                            availability or cost, be prepared for a longer
                            journey to reach downtown Ottawa.
                          </p>
                        </div>
                        <br />
                        <div className="notes">
                          Please note that any promotional codes or discounts
                          provided apply only to flights arriving at Ottawa
                          Macdonald-Cartier International Airport (YOW).
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="dress-code event-details">
                        <div className="label-header">Ottawa Airport</div>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5611.067165816268!2d-75.66846382317813!3d45.317846643191025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce083f06d206e1%3A0x50fd37ed2e082d1a!2sOttawa%20International%20Airport!5e0!3m2!1sen!2sca!4v1712247164954!5m2!1sen!2sca"
                          width="600"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          className="event-map"
                          title="Ottawa Airport"
                          alt="hello"
                        ></iframe>

                        <div className="label-description">
                          <p>
                            Distance to Downtown Ottawa: Approximately 10
                            kilometers (6.2 miles)
                          </p>
                          <p>
                            Travel Time to Downtown: About 20-30 minutes by car,
                            depending on traffic conditions
                          </p>
                        </div>
                      </div>
                      <div className="parking event-details">
                        <div className="label-header">Montreal Airport</div>

                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5596.408545771885!2d-73.74805632317248!3d45.46568883333858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93d7753d8c92b%3A0x1e30a8791014678d!2sMontr%C3%A9al-Pierre%20Elliott%20Trudeau%20International%20Airport!5e0!3m2!1sen!2sca!4v1712247254046!5m2!1sen!2sca"
                          width="600"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          className="event-map"
                          title="Montreal Airport"
                          alt="hello"
                        ></iframe>
                        <div className="label-description">
                          <p>
                            Distance to Downtown Ottawa: Approximately 180
                            kilometers (112 miles)
                          </p>
                          <p>
                            {" "}
                            Travel Time to Downtown: About 2-2.5 hours by car,
                            depending on traffic and road conditions
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-location" id="air-canada">
                  <div className="location-image-container">
                    <img
                      className="location-image"
                      src={
                        "https://pbs.twimg.com/media/FT6nHKUXsAAMSPJ.jpg:large"
                      }
                      alt="nac"
                    />
                  </div>
                  <div className="location-details">
                    <div className="location">
                      <div className="event-address">Air Canada</div>
                    </div>
                    <div>
                      <div className="dress-code event-details">
                        <div className="label-header">How to use</div>
                        <div className="label-description">
                          <p>
                            To book a flight with your promotion code, access{" "}
                            <a
                              href="https://www.aircanada.com"
                              alt="Air Canada"
                              target="_blank"
                              title="Air Canada"
                              rel="noreferrer"
                            >
                              aircanada.com
                            </a>{" "}
                            and enter your
                            <strong> PEYD7H61 </strong>
                            in the Promotion Code Box.
                          </p>
                        </div>
                      </div>
                      <br />
                      <Button
                        variant="outlined"
                        href="https://www.aircanada.com"
                        target="_blank"
                      >
                        Book flight with United
                      </Button>
                      <br />
                      <br />
                      <div className="parking event-details">
                        <div className="label-header">Applicable Rules</div>
                        <div className="label-description">
                          <p>
                            <ul>
                              <li>
                                {" "}
                                - The booking is to be made to the following
                                city Ottawa, YOW (CA)
                              </li>
                              <li>
                                {" "}
                                - The travel period begins Sunday, August 25,
                                2024 and ends Sunday, September 15, 2024. Travel
                                is valid Monday, Tuesday, Wednesday, Thursday,
                                Friday, Saturday, Sunday.
                              </li>
                              <li>
                                {" "}
                                - For North America' 5% applies on standard
                                fares, 10% on flex fares & higher. For
                                International Travel' 10% on standard fares &
                                higher.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-location" id="united">
                  <div className="location-image-container">
                    <img
                      className="location-image"
                      src={
                        "https://thenoah.net/wp-content/uploads/2023/06/united-airlines-logo-emblem-png.png"
                      }
                      alt="nac"
                    />
                  </div>
                  <div className="location-details">
                    <div className="location">
                      <div className="event-address">United Airlines</div>
                    </div>
                    <div>
                      <div className="dress-code event-details">
                        <div className="label-header">How to use</div>

                        <div className="label-description">
                          <p>
                            To make flight reservations online please click on
                            discount code <strong> ZPM9442374 </strong>
                            MileagePlus members earn valuable miles for their
                            travel when using the Meetings discount code.
                          </p>
                        </div>
                        <br />
                        <Button
                          variant="outlined"
                          href="https://www.united.com/en/us/book-flight/united-reservations?cid=5509ee83-f106-489f-8267-4fa9dd9ad7aa&vendorQuery=true&fu=t"
                          target="_blank"
                        >
                          Book flight with United
                        </Button>
                        <br />
                        <br />
                        <div className="label-description">
                          <p>
                            You may also call United Meetings Reservation Desk
                            Monday – Friday at (800) 426-1122 for booking
                            assistance. Booking fees are waived for Meeting
                            reservations. International customers may contact
                            their local United Reservation Desk.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel className="hotel-container">
          <div className="hotel-tab">
            <div className="event-section">
              <div className="event">
                <div className="event-header">
                  We have partnered with two hotels
                  <ButtonGroup>
                    <Button onClick={() => scrollToDiv("lord-elgin")}>
                      Lord Elgin
                    </Button>
                    <Button onClick={() => scrollToDiv("novotel")}>
                      Novotel
                    </Button>
                  </ButtonGroup>
                </div>

                <div className="event-location" id="lord-elgin">
                  <div className="location-image-container">
                    <img
                      className="location-image"
                      src={lord_elgin}
                      alt="nac"
                    />
                  </div>
                  <div className="location-details">
                    <div className="location">
                      <div className="event-address">Lord Elgin</div>
                      <div className="notes">
                        It takes approximately 2-3 minutes to walk from Lord
                        Elgin to the National Arts Centre.
                      </div>
                      <div className="notes">
                        <strong>The couple will be staying here!!! </strong>
                      </div>
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.401311252235!2d-75.69623052317414!3d45.42141103629181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce05aa7df41401%3A0x68e9ea08d2d22227!2sLord%20Elgin%20Hotel!5e0!3m2!1sen!2sca!4v1712458563446!5m2!1sen!2sca"
                      width="600"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      className="event-map"
                      title="Montreal Airport"
                      alt="hello"
                    ></iframe>
                    <div>
                      <div className="dress-code event-details">
                        <div className="label-header">How to book</div>
                        <div className="label-description">
                          <p>
                            Please use the following link to book your
                            accommodations directly through our website. If you
                            prefer to call the hotel to make reservations,
                            please provide them with the group block number:{" "}
                            <strong>5127978</strong>.
                          </p>
                        </div>
                        <br />
                        <Button
                          size="small"
                          variant="outlined"
                          target="_blank"
                          href="https://reservations.travelclick.com/12200?groupID=4145070"
                        >
                          Book hotel room
                        </Button>
                        <br />
                        <br />
                        <div className="label-description">
                          <p>
                            Should you encounter any difficulties with the
                            booking link or wish to book outside of the block
                            dates, kindly reach out to the hotel reservations
                            team via email at{" "}
                            <a href="mailto:groups@lordelgin.ca?subject=Booking%20Inquiry&body=Dear%20Reservation%20Team,%0D%0A%0D%0AI%20am%20writing%20to%20inquire%20about%20booking%20accommodations%20for%20our%20upcoming%20wedding.%20Please%20assist%20me%20with%20the%20booking%20process.%0D%0A%0D%0AThank%20you.%0D%0A%0D%0A">
                              groups@lordelgin.ca
                            </a>{" "}
                            or by calling the hotel directly at 613-235-3333.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="event-location" id="novotel">
                  <div className="location-image-container">
                    <img
                      className="location-image"
                      src={"https://www.novotelottawa.com/photos/profile.jpg"}
                      alt="nac"
                    />
                  </div>
                  <div className="location-details">
                    <div className="location">
                      <div className="event-address">Novotel</div>
                      <div className="notes">
                        It takes approximately 10-15 minutes to walk from Lord
                        Elgin to the National Arts Centre.
                      </div>
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.2973640272057!2d-75.69340267317406!3d45.42350668615215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce0501665e2d6d%3A0x69704635bc4dae47!2sNovotel%20Ottawa%20City%20Centre!5e0!3m2!1sen!2sca!4v1712459783912!5m2!1sen!2sca"
                      width="600"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      className="event-map"
                      title="Montreal Airport"
                      alt="hello"
                    ></iframe>

                    <div className="dress-code event-details">
                      <div className="label-header">How to Book</div>
                      <div className="label-description">
                        <p>
                          Simply use the exclusive code{" "}
                          <strong> 1238953 </strong> or mention the group name
                          <strong> Dewan Wedding </strong> when making your
                          reservation.
                        </p>
                        <br />
                        <p>
                          To reserve your room, you can reach out to us via
                          email at{" "}
                          <a href="mailto:novotelottawa@novotelottawa.com?subject=Dewan%20Wedding%20Reservation&body=Hello%20Novotel%20Ottawa%20Team,%0D%0A%0D%0AI%20would%20like%20to%20make%20a%20reservation%20for%20the%20Dewan%20Wedding.%20Please%20provide%20me%20with%20further%20details%20regarding%20availability%20and%20rates.%0D%0A%0D%0AExclusive%20Code:%201238953%0D%0A%0D%0AThank%20you.%0D%0A%0D%0A">
                            novotelottawa@novotelottawa.com
                          </a>{" "}
                          or give us a call at 613-230-3033. Our dedicated team
                          is here to ensure your booking process is seamless.
                        </p>
                      </div>
                    </div>
                    <div className="parking event-details">
                      <div className="label-header">
                        Important details to keep in mind
                      </div>
                      <div className="label-description">
                        <p>
                          <ul>
                            <li>
                              - Check in time is 3PM (if anyone needs an early
                              check in, we would do our best to make this
                              happen, however, it is not guaranteed as it
                              depends on our occupancy the night before).
                            </li>
                            <li>
                              - The special rate is valid until July 17th, 2024.
                            </li>
                            <li>
                              - Cancellations and modifications are permitted up
                              to 72 hours prior to your scheduled arrival date.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TravelTabs;
