const { beef, fish, veg } = require("./images");

module.exports = {
  rsvp: {
    status: {
      attending: "Attending",
      notAttending: "Not Attending",
    },
    rsvpCeremony: {
      name: "RSVP Ceremony",
      key: "rsvpCeremony",
      label: "Will you be attending the Ceremony?",
      additionalInfo: "",
      options: [
        { value: "Attending", label: "JOYFULLY ACCEPTS" },
        { value: "Not Attending", label: "REGRETFULLY DECLINES" },
      ],
    },
    rsvpReception: {
      name: "RSVP Reception",
      key: "rsvpReception",
      label: "Will you be attending the Reception?",
      additionalInfo: "",
      options: [
        { value: "Attending", label: "JOYFULLY ACCEPTS" },
        { value: "Not Attending", label: "REGRETFULLY DECLINES" },
      ],
    },

    meal_selection: {
      name: "meal selection",
      key: "meal_selection",
      placeholder: "Meal Selection",
      label: "Reception Meal Options",
      options: [
        {
          value: "Beef",
          label: "Canadian Whiskey Short Rib",
          image: beef,
          description:
            "brown butter mashed potato, charred Brussels sprouts with double smoked bacon",
        },
        {
          value: "Fish",
          label: "Seared Atlantic Salmon",
          image: fish,
          description:
            "with citrus gremolata, pearled couscous braised Swiss char and fennel chardonnay cream",
        },
        {
          value: "Vegetarian",
          label: "Grilled Vegetable Tian",
          image: veg,
          description:
            "infused with herb, garlic and fine balsamic vinegar, red pepper coulis",
        },
      ],
    },
    dietary_restrictions: {
      name: "Dietary Restrictions",
      key: "dietary_restrictions",
      label: "Are there any dietary restrictions we should be aware of?",
      placeholder: "Dietary Restrictions",
      options: [
        { value: "gluten_free", label: "Gluten-Free" },
        { value: "vegetarian", label: "Vegetarian" },
        { value: "vegan", label: "Vegan" },
        { value: "halal", label: "Halal" },
        { value: "kosher", label: "Kosher" },
        { value: "nut_free", label: "Nut-Free" },
        { value: "lactose_intolerant", label: "Lactose Intolerant" },
        { value: "shellfish_allergy", label: "Shellfish Allergy" },
        { value: "celiac", label: "Celiac" },
        { value: "dairy_free", label: "Dairy-Free" },
      ],
    },
    rsvpRehearsal: {
      name: "RSVP Rehearsal Dinner",
      key: "rsvpRehearsal",
      label: "Will you be attending the Rehearsal Dinner",
      additionalInfo: "",
      options: [
        { value: "Attending", label: "JOYFULLY ACCEPTS" },
        { value: "Not Attending", label: "REGRETFULLY DECLINES" },
      ],
    },
  },
  bridalPaartyCalendar: {
    name: "Analeeze Mendonsa & Jack Dewan",
    dates: [
      {
        name: "Analeeze Mendonsa & Jack Dewan Rehearsal Ceremony",
        description:
          "This rehearsal ceremony is exclusively for the bridal party and their partners. This is for a run-through of Analeeze Mendonsa & Jack Dewan's wedding ceremony, ensuring that everything is perfect for the big day. Please note that the dress code for this event is business casual.",
        startDate: "2024-09-05",
        startTime: "18:30",
        endTime: "19:30",
        location:
          "Notre Dame Cathedral Basilica 56 Guigues Ave, Ottawa, Ontario K1N 5H5",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Rehearsal Dinner",
        description:
          "After the rehearsal ceremony will have a small intimate dinner with the Bridal Party and their partners. Please note that the dress code for this event is business casual.",
        startDate: "2024-09-05",
        startTime: "19:30",
        endTime: "21:00",
        location: "1 Elgin Restaurant 1 Elgin Street, Ottawa, ON K1P 5W1 ",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Wedding Ceremony",
        description:
          "Everyone is welcome, including little ones, to celebrate the wedding ceremony of Analeeze Mendonsa & Jack Dewan. Witness as they exchange vows and embark on their journey together as husband and wife. Your presence will make this joyous occasion even more memorable. Please note that the dress code for this event is formal.",
        startDate: "2024-09-07",
        startTime: "10:45",
        endTime: "12:00",
        location:
          "Notre Dame Cathedral Basilica 56 Guigues Ave, Ottawa, Ontario K1N 5H5",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Wedding Reception",
        description:
          "Join us for the wedding reception of Analeeze Mendonsa & Jack Dewan. This event is open only to those named on the RSVP list. Please note that the dress code for this formal affair is formal attire. Cocktail hour will commence at 5:30 PM, followed by dinner and celebration.",
        startDate: "2024-09-07",
        startTime: "17:30",
        endDate: "2024-09-08",
        endTime: "01:00",
        location: "National Art Center 1 Elgin Street, Ottawa, ON K1P 5W1",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Stag & Doe",
        description:
          "Stag & Doe celebration for Analeeze Mendonsa & Jack Dewan. Come join us for a night of fun, games, and laughter as we celebrate the upcoming wedding of the happy couple. Location and other details to come.",
        startDate: "2024-08-31",
        location: "TBD",
      },
    ],
    label: "Add to your calendar",
    iCalFileName: "Analeeze Mendonsa & Jack Dewan Wedding ",
    timeZone: "America/New_York",
    options: "'Apple','Google','iCal','Outlook.com','Yahoo'",
    lightMode: "bodyScheme",
  },
  calendar: {
    name: "Analeeze Mendonsa & Jack Dewan",
    dates: [
      {
        name: "Analeeze Mendonsa & Jack Dewan Wedding Ceremony",
        description:
          "Everyone is welcome, including little ones, to celebrate the wedding ceremony of Analeeze Mendonsa & Jack Dewan. Witness as they exchange vows and embark on their journey together as husband and wife. Your presence will make this joyous occasion even more memorable. Please note that the dress code for this event is formal.",
        startDate: "2024-09-07",
        startTime: "10:45",
        endTime: "12:00",
        location:
          "Notre Dame Cathedral Basilica 56 Guigues Ave, Ottawa, Ontario K1N 5H5",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Wedding Reception",
        description:
          "Join us for the wedding reception of Analeeze Mendonsa & Jack Dewan. This event is open only to those named on the RSVP list. Please note that the dress code for this formal affair is formal attire. Cocktail hour will commence at 5:30 PM, followed by dinner and celebration.",
        startDate: "2024-09-07",
        startTime: "17:30",
        endDate: "2024-09-08",
        endTime: "01:00",
        location: "National Art Center 1 Elgin Street, Ottawa, ON K1P 5W1",
      },
      {
        name: "Analeeze Mendonsa & Jack Dewan Stag & Doe",
        description:
          "Stag & Doe celebration for Analeeze Mendonsa & Jack Dewan. Come join us for a night of fun, games, and laughter as we celebrate the upcoming wedding of the happy couple. Location and other details to come.",
        startDate: "2024-08-31",
        location: "TBD",
      },
    ],
    label: "Add to your calendar",
    iCalFileName: "Analeeze Mendonsa & Jack Dewan Wedding ",
    timeZone: "America/New_York",
    options: "'Apple','Google','iCal','Outlook.com','Yahoo'",
    lightMode: "bodyScheme",
  },
};
